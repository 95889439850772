import { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import spells from 'data/spells';
import { Spells } from 'data/metadata';
import { DataGridDisplay } from 'components/afterworld/data/GridDisplay';
import { PageHeading } from 'components/elements';
import { Spell } from 'components/afterworld/data/Spell';

const filterAlternative = (schools, alternativeMethod) => {
  switch (alternativeMethod) {
    case 'mono': return schools.length === 1;
    case 'multi': return schools.length > 1;
  }
};

const costFilterMapping = {
  EMPTY: [],
  LOW: [0, 1, 2, 3],
  MEDIUM: [4, 5, 6],
  HIGH: [7, 8, 9, 10],
}

export function DataSpellPage() {
  const [filter, setFilter] = useState({ alternative: '', cost: 'EMPTY', school: '', type: '' });
  const [search, setSearch] = useState('');

  const costFilter = filter.cost ? costFilterMapping[filter.cost] : [];
  const filteredData = spells.filter((datum) => (
    (!costFilter.length || costFilter.includes(datum.cost))
    && (!filter.school || datum.schools.filter((school) => school.name === filter.school).length)
    && (!filter.alternative || filterAlternative(datum.schools, filter.alternative))
    && (!filter.type || datum.types.filter((type) => type.includes(filter.type)).length)
    && (!search || datum.name.toLowerCase().includes(search))
  ));

  return (
    <>
      <PageHeading>
        Sorts
      </PageHeading>

      <Box sx={{ display: 'grid', gridTemplateColumns: { lg: '50% 50%', md: '100%' }, gap: 1, mb: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="afterworld-spells__list-filter-school__label">
            École
          </InputLabel>

          <Select
            label="École"
            labelId="afterworld-spells__list-filter-school__label"
            onChange={(e) => { setFilter({ ...filter, school: e.target.value }); }}
            size="small"
            value={filter.school}
          >
            <MenuItem value=""><em>Aucun filtre</em></MenuItem>
            {Object.entries(Spells.School).map(([schoolKey, schoolName]) => (
              <MenuItem key={schoolKey} value={schoolName}>{schoolName}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="afterworld-spells__list-filter-alternative__label">
            Alternatives
          </InputLabel>

          <Select
            label="Alternative"
            labelId="afterworld-spells__list-filter-alternative__label"
            onChange={(e) => { setFilter({ ...filter, alternative: e.target.value }); }}
            size="small"
            value={filter.alternative}
          >
            <MenuItem value=""><em>Aucun filtre</em></MenuItem>
            <MenuItem value="mono">Mono-école</MenuItem>
            <MenuItem value="multi">Multi-écoles</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="afterworld-spells__list-filter-cost__label">
            Coût
          </InputLabel>

          <Select
            label="Coût"
            labelId="afterworld-spells__list-filter-cost__label"
            onChange={(e) => { setFilter({ ...filter, cost: e.target.value }); }}
            size="small"
            value={filter.cost}
          >
            <MenuItem value={`EMPTY`}><em>Aucun filtre</em></MenuItem>
            <MenuItem value={`LOW`}>≤ 3</MenuItem>
            <MenuItem value={`MEDIUM`}>4 - 6</MenuItem>
            <MenuItem value={`HIGH`}>≥ 7</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="afterworld-spells__list-filter-type__label">
            Type
          </InputLabel>

          <Select
            label="Type"
            labelId="afterworld-spells__list-filter-type__label"
            onChange={(e) => { setFilter({ ...filter, type: e.target.value }); }}
            size="small"
            value={filter.type}
          >
            <MenuItem value=""><em>Aucun filtre</em></MenuItem>
            {Object.entries(Spells.Types).map(([typeKey, typeName]) => (
              <MenuItem key={typeKey} value={typeName}>{typeName}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>


      <FormControl sx={{ mb: 1, width: '100%' }}>
        <TextField
          id="afterworld-spells__list-search"
          label="Rechercher le sort"
          onChange={(e) => { setSearch(e.target.value.toLowerCase()); }}
          size="small"
          value={search}
          variant="outlined"
        />
      </FormControl>

      <DataGridDisplay
        data={filteredData}
        density="comfortable"
        mobileComponent={Spell}
      />
    </>
  );
}
