import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    additionalEffects: [
      `Effet : Fatigue +1 sur la personne`,
      `Effet : Fatigue -1 pour le lanceur`,
      `Surcharge : double les changements de Fatigue`,
    ],
    cost: 2,
    description: `Aspire la vie hors d'une personne afin de se renforcer.`,
    name: `Absorption de vitalité`,
    requirements: [
      `contact`,
      `composant (fiole de sang)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : réduit de moitié la durée des saignements ou augmente leur intensité de 1`,
      `Surcharge : annule les saignements ou augmente leur intensité de 2`,
    ],
    cost: 2,
    description: `Accélère ou réduit le facteur de coagulation d'une personne.`,
    name: `Changement de coagulation`,
    requirements: [
      `contact`,
      `composant (fiole de sang)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Initiative -5`,
      `Surcharge : Initiative -10`,
    ],
    cost: 2,
    description: `Force les affres de l'âge à agir prématurément sur une personne.`,
    name: `Faiblesse de l'âge`,
    requirements: [
      `contact`,
      `composant (fiole de sang)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} -20`,
      `Surcharge : inflige l'état de santé « peur »`,
    ],
    cost: 2,
    description: `Intensifie une ombre présente de sorte qu'elle soit tellement dense qu'on n'y voit plus au travers.`,
    name: `Intensification d'une ombre`,
    requirements: [
      `luminosité ambiante`,
      `ombre`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 2,
    description: `Accélère la décomposition d'un cadavre de telle sorte qu'il ne reste que les os. Lancer ce sort sur des ossements les réduit en poussière, ce qui permet de récolter de la poudre d'os. Ce sort permet aussi d'accélérer la pourriture de matériaux tels que le bois ou le cuir.`,
    name: `Putréfaction accélérée`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.VIGOR} -50`,
      `Surcharge : ${CHARACTER.SKILLS.DEFENSES.VIGOR} -100`,
    ],
    cost: 2,
    description: `Dépossède le sang d'une personne de ses globules blancs, affaiblissant son système immunitaire et la rendant vulnérable aux infections.`,
    name: `Sang vicié`,
    requirements: [
      `contact`,
      `composant (fiole de sang)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Surcharge : détecte les personnes décédée depuis les 7 derniers jours`],
    area: `1 m²`,
    cost: 2,
    description: `Repère dans la zone alentour où se trouvent les gens qui viennent de décéder dans les dernières 24 heures. Permet également de détecter les moisissures comme les champignons.`,
    name: `Sens de la mort`,
    requirements: [`composant (fiole de sang)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : Fatigue +1 à la personne`,
      `Attention : selon les groupes sanguins, la personne peut souffrir de fièvre, frissons, vomissements ou mourir`,
    ],
    cost: 2,
    description: `Réinjecte du sang dans le corps de la personne.`,
    name: `Transfusion`,
    requirements: [
      `contact`,
      `composant (fiole de sang)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Attention : les attaques qui touchent peuvent provoquer selon les groupes sanguins, de la fièvre, des frissons, des vomissements ou tuer la personne`,
      `Attention : le malus au combat des élémentaires ne s'applique pas aux armes invoquées`,
    ],
    cost: 4,
    description: `Invoque magiquement une arme de corps-à-corps en sang coagulé, n'importe laquelle.`,
    name: `Arme ensanglantée`,
    requirements: [
      `relique (arme de corps-à-corps)`,
      `composant (fiole de sang)`,
    ],
    types: [Spells.Types.SUMMON],
  },
  {
    additionalEffects: [
      `Effet : armure +3`,
      `Effet : blessures à mains nues +3 (tranchants)`,
      `Surcharge : double l'armure et les blessures`,
    ],
    cost: 4,
    description: `Invoque magiquement une armure en os.`,
    name: `Armure d'os`,
    requirements: [`composant (poudre d'os)`],
    types: [Spells.Types.SUMMON],
  },
  {
    cost: 4,
    damage: `8`,
    description: `Invoque magiquement un être horrifique fait d'ossements, de sang visqueux et coagulé et de morceaux de chair pourris.`,
    name: `Invocation de golem de sang`,
    requirements: [
      `composant (fiole de sang)`,
      `composant (poudre d'os)`,
    ],
    summon: [
      `Toucher : 80 %`,
      `Attaque : inflige Radiations II, Poison type D et une maladie aléatoire`,
      `Taille : 170 cm`,
      `Poids : 60 kg`,
    ],
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : les personnes doivent quitter la zone sauf sur un test de Volonté réussi`,
      `Surcharge : les personnes tombent inconscientes sauf sur un test de Volonté réussi`,
    ],
    area: `1 m²`,
    cost: 4,
    description: `Créé un nuage de putréfaction avancé autour d'une personne dont l'odeur fait fuir même les enquêteurs de tueurs en série.`,
    name: `Nuage nauséabond`,
    requirements: [`composant (morceau de chair)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Surcharge : permet à l'ombre d'interagir, d'attaquer des chevilles, de désarmer ou d'attaquer d'autres personnes`],
    cost: 4,
    description: `Manipule une ombre afin d'obtenir de l'aide. Celle-ci peut engainer ou dégainer une arme, prendre ou lâcher des objets ou chercher un objet dans un sac.`,
    name: `Ombre asservie`,
    requirements: [`Ombre intensifiée`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : Psychose +1 sur le lanceur`,
      `Surcharge : permet de de faire combattre la personne réanimée`,
    ],
    cost: 4,
    description: `Ramène une personne humanoïde décédée il y a moins de 24 heures du plan des morts dans son enveloppe charnelle afin de discuter avec lui.`,
    name: `Réanimation`,
    requirements: [
      `contact`,
      `composant (fiole de sang)`,
      `composant (poudre d'os)`,
    ],
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CHANNEL,
    ],
  },
  {
    additionalEffects: [`Effet : ${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} -30`],
    area: `10 m²`,
    cost: 4,
    description: `Plonge une zone dans les ténèbres, même s'il fait jour, ce qui réduit la visibilité et peut perturber les gens.`,
    name: `Ténèbres`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : soigne une blessure`,
      `Effet : Psychose +2 sur le lanceur`,
      `Surcharge : soigne toutes les blessures mais Psychose +5`,
    ],
    cost: 6,
    description: `Aspire toute l'énergie vitale d'une personne morte depuis moins de 24 heures ce qui a des vertus régénérantes.`,
    name: `Absorption d'énergie`,
    requirements: [
      `contact`,
      `composant (cadavre frais)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : si le lanceur décède à côté de la relique, il revient à la vie 10 secondes après`,
      `Effet : Psychose +3 pour le lanceur`,
      `Surcharge : permet au lanceur de revenir à la vie même s'il n'est pas décédé à côté de la relique, sous certaines conditions et nécessitant certains rituels`,
    ],
    cost: 6,
    description: `Sépare une partie de l'âme afin de la sceller dans un objet et de la conserver précieusement.`,
    name: `Brise-âme`,
    requirements: [
      `contact`,
      `relique (objet quelconque)`,
      `composant (fiole de sang)`,
      `composant (poudre d'os)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : les maladies transmissibles par le sang peuvent s'attraper de la sorte`,
      `Surcharge : augmente les blessures à 5 + 1d12`,
    ],
    area: `2 m²`,
    cost: 6,
    damage: `3 + 1d8 (perforants)`,
    description: `Le cadavre d'une personne décédée il y a moins de 24 heures explose telle une mine.`,
    name: `Explosion de cadavre`,
    requirements: [
      `contact`,
      `composant (cadavre frais)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : inflige Saignement III permanent`,
      `Surcharge : inflige Saignement Ⅳ permanent à la place`,
    ],
    cost: 6,
    description: `Provoque une hémorragie sur une personne ce qui va la mener à la mort faute de soins.`,
    name: `Hémorragie`,
    requirements: [
      `contact`,
      `composant (fiole de sang)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : provoque une maladie aléatoire par tour dans la zone`,
      `Surcharge : octroie une maladie aléatoire supplémentaire à chaque fois`,
    ],
    area: `5 m²`,
    cost: 6,
    description: `Des bulles de pourriture et de maladie jaillissent du sol dans une large zone et explosent.`,
    name: `Mort et décomposition`,
    requirements: [
      `contact`,
      `composant (fiole de sang)`,
      `composant (morceau de chair)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Attention : les personnes touchées peuvent subir, selon leurs groupes sanguins, de la fièvre, des frissons, des vomissements ou mourir`,
      `Surcharge : augmente les blessures à 7 + 1d6`,
    ],
    area: `1 m²`,
    cost: 6,
    damage: `3 + 1d4`,
    description: `Crée magiquement des éclats de sang cristallisés dans les airs dans la zone alentour qui vont tomber sur les personnes proches et leur infliger de fines entailles.`,
    name: `Pluie de sang`,
    requirements: [
      `contact`,
      `composant (fiole de sang)`,
    ],
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : le sort doit être lancé puis canalisé pendant 3 tours avant que l'organe soit complètement pourri`,
      `Surcharge : réduit le temps de canalisation nécessaire à 1 tour`,
    ],
    cost: 6,
    description: `Un organe à l'intérieur du corps d'une personne commence à pourrir.`,
    name: `Pourriture d'organe`,
    requirements: [
      `contact`,
      `connaissances de l'anatomie de la cible`,
      `composant (morceau de chair)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
]);

export default data.map((i) => ({
  ...i,
  schools: [{ name: Spells.School.SHADOW_DEATH }],
}));
