import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import monsters from 'data/monsters';
import { DataGridDisplay } from 'components/afterworld/data/GridDisplay';
import { Monster } from 'components/afterworld/data/Monster';
import { Monsters } from 'data/metadata';
import { PageHeading } from 'components/elements';
import { PageRootContainer } from 'layout/PageRootContainer';

export function BestiaryPage() {
  const [filter, setFilter] = useState({ type: '' });
  const [search, setSearch] = useState('');

  const filteredData = monsters.filter((datum) => (
    (!filter.type || datum.types.includes(filter.type))
    && (!search || datum.name.toLowerCase().includes(search))
  ));

  return (
    <PageRootContainer target="/">
      <PageHeading>
        Bestiaire
      </PageHeading>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <InputLabel id="afterworld-bestiary__type-filter__type">
          Type
        </InputLabel>

        <Select
          label="Alternative"
          labelId="afterworld-bestiary__type-filter__type"
          onChange={(e) => { setFilter({ ...filter, type: e.target.value }); }}
          size="small"
          value={filter.type}
        >
          <MenuItem value=""><em>Aucun filtre</em></MenuItem>
          {Object.values(Monsters.Type).map((type) => (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <TextField
          id="afterworld-bestiary__list-search"
          label="Rechercher le monstre"
          onChange={(e) => { setSearch(e.target.value.toLowerCase()); }}
          size="small"
          value={search}
          variant="outlined"
        />
      </FormControl>

      <DataGridDisplay
        data={filteredData}
        mobileComponent={Monster}
      />
    </PageRootContainer>
  );
}
