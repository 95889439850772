export class GiftSlot {

  #effects;
  #level;
  #gifts;

  constructor(effects, level) {
    this.#effects = effects;
    this.#level = level;
    this.#gifts = [];
  }

  getGifts() {
    return this.#gifts;
  }

  isLegal() {
    if (this.#gifts.length > 2) {
      throw new Error(`Trop d'aptitudes dans cet emplacement.`);
    } else if (this.#gifts.length === 2) {
      this.#gifts.forEach((gift) => {
        if (gift.level >= this.#level) {
          throw new Error(`Les aptitudes sélectionnées dans cet emplacement ont un niveau trop élevé.`);
        }
      });
    } else if (this.#gifts.length) {
      if (this.#gifts[0].level > this.#level) {
        throw new Error(`L'aptitude sélectionnée dans cet emplacement a un niveau trop élevé.`);
      }
    }
    return true;
  }

  static createFullSet(effects) {
    return [
      new GiftSlot(effects, 1),
      new GiftSlot(effects, 1),
      new GiftSlot(effects, 2),
      new GiftSlot(effects, 2),
      new GiftSlot(effects, 2),
      new GiftSlot(effects, 5),
      new GiftSlot(effects, 5),
      new GiftSlot(effects, 5),
      new GiftSlot(effects, 5),
      new GiftSlot(effects, 5),
      new GiftSlot(effects, 10),
      new GiftSlot(effects, 10),
      new GiftSlot(effects, 10),
      new GiftSlot(effects, 10),
      new GiftSlot(effects, 10),
      new GiftSlot(effects, 15),
      new GiftSlot(effects, 15),
      new GiftSlot(effects, 15),
      new GiftSlot(effects, 15),
      new GiftSlot(effects, 15),
      new GiftSlot(effects, 15),
    ];
  }

}

export class Gift {

  #name;
  #level;
  #effects;

  constructor(name, level, effects) {
    this.#name = name;
    this.#level = level;
    this.#effects = effects;
  }

}
