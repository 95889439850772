import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { Character, Skill } from 'lib/character';

// Custom replacer function to include class information
function replacer(key, value) {
  if (value instanceof Character) {
    return { ...value, __class: 'Character' };
  }
  if (value instanceof Skill) {
    return { ...value, __class: 'Skill' };
  }
  return value;
}

// Custom reviver function to restore class prototypes
function reviver(key, value) {
  if (value && value.__class === 'Character') {
    const character = Object.assign(new Character(), value);
    // character.skills = character.skills.map(skill => Object.setPrototypeOf(skill, Skill.prototype));
    return character;
  }
  if (value && value.__class === 'Skill') {
    return Object.assign(new Skill(), value);
  }
  return value;
}

export const useCharactersStore = create(
  persist(
    (set) => ({
      characters: {},
      addCharacter: (character) => set((state) => ({ characters: { ...state.characters, [character.getId()]: character } })),
      editCharacter: (character) => set((state) => ({ characters: { ...state.characters, [character.getId()]: character } })),
      removeCharacter: (character) => set((state) => {
        const characters = { ...state.characters };
        delete characters[character.getId()];
        return { characters };
      }),
    }),
    {
      name: 'afterworld-storage-characters',
      storage: createJSONStorage(() => localStorage, {
        replacer,
        reviver,
      }),
    },
  ),
);
