import { useParams } from 'react-router-dom';

import { CharacterMagicTable } from 'components/character/CharacterMagicTable';
import { useCharactersStore } from 'store';

export function CharacterMagicPage() {
  const params = useParams();
  const characterId = params['*'].split('/').pop();
  const character = useCharactersStore((state) => state.characters[characterId]);

  return (
    <CharacterMagicTable data={character.magic} />
  );
}
