import { useParams } from 'react-router-dom';

import { useCharactersStore } from 'store';

export function CharacterGiftsPage() {
  const params = useParams();
  const characterId = params['*'].split('/').pop();
  const character = useCharactersStore((state) => state.characters[characterId]);

  return (
    <p>
      Items: {character.name}
    </p>
  );
}
