import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    additionalEffects: [
      `Effet : poids de l'objet ÷ 2 ou x 2`,
      `Surcharge : poids de l'objet ÷ 3 ou x 3 à la place`,
    ],
    cost: 2,
    description: `Allège ou alourdi temporairement un objet.`,
    name: `Changement de poids`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : réduit de moitié la durée restante des effets de Brûlure`,
      `Surcharge : consume les effets de Brûlure sur la personne et lui inflige l'ensemble des dégâts immédiatement`,
    ],
    area: `1 m³`,
    cost: 2,
    description: `Réduit l'ampleur d'un feu, d'une bougie, d'un petit feu de camp ou de flammes sur une personne.`,
    name: `Conflagration`,
    requirements: [`proximité`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Surcharge : détecte l'ensemble des matériaux présents dans la zone`],
    area: `1 m²`,
    cost: 2,
    description: `Détecte dans une zone alentour si le matériau recherché est présent.`,
    name: `Détection des matériaux`,
    requirements: [
      `contact`,
      `composant (échantillon du matériau recherché)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Surcharge : étend l'effet à un livre ou à un panneau publicitaire`],
    cost: 2,
    description: `Efface temporairement le texte d'une page, d'un parchemin ou d'une enseigne.`,
    name: `Effacement`,
    requirements: [
      `contact`,
      `composant (poussière)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 2,
    description: `Enflamme la cible et lui inflige Brûlure I pendant 2 tours.`,
    name: `Incinération`,
    requirements: [`relique (source de feu, briquet, allumettes, ...)`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : aveugle la personne pour 6 tours (30 secondes)`,
      `Surcharge : dure 12 tours à la place (1 minute)`,
    ],
    cost: 2,
    description: `Envoie magiquement un jet de poussière dans les yeux d'une cible.`,
    name: `Jet de poussière`,
    requirements: [`composant (poussière)`],
    types: [Spells.Types.CAST],
  },
  {
    cost: 2,
    description: `Remplit un moule de métal, ce qui permet de déverrouiller une serrure, boucher des tuyaux ou encore colmater un trou dans une coque.`,
    name: `Remplissage`,
    requirements: [
      `contact`,
      `composant (ferraille)`,
    ],
    types: [Spells.Types.SUMMON],
  },
  {
    additionalEffects: [
      `Effet : augmente de 1 tour la durée des effets de brûlure`,
      `Effet : température +4°`,
      `Surcharge : augmente de 1 l'intensité des effets de brûlure`,
      `Surcharge : température +4° supplémentaires`,
    ],
    area: `1 m³`,
    cost: 2,
    description: `Augmente la température dans la zone alentour.`,
    name: `Zone ardente`,
    requirements: [`proximité`],
    types: [Spells.Types.CHANNEL],
  },
  {
    additionalEffects: [
      `Effet : inflige Brûlure I pendant 3 tours`,
      `Effet : renverse la cible au sol`,
    ],
    cost: 4,
    damage: `8 + 1d4 (contondants)`,
    description: `Créé magiquement un projectile fait de flammes et le projette vers une cible.`,
    name: `Boule de feu`,
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Surcharge : détecte les déplacements souterrains en plus de ceux en surface`],
    area: `5 m²`,
    cost: 4,
    description: `Détecte les pas et déplacements humains et animaux dans la zone alentour.`,
    name: `Détection du mouvement`,
    requirements: [
      `contact`,
      `sol qui peut vibrer (terre, bois, béton, …)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : les attaques infligent Brûlure I pour 2 tours`,
      `Effet : augmente les blessures tranchantes ou perforantes de 2`,
      `Effet : inflige Brûlure I au porteur par tour de maintien de l'objet`,
      `Surcharge : augmente l'intensité des brûlures de I`,
    ],
    cost: 4,
    description: `Fait apparaître magiquement du feu autour d'un objet, d'une arme, d'un projectile ou de mains.`,
    name: `Feu magique`,
    requirements: [
      `contact`,
      `relique (source de feu, briquet, allumettes, ...)`,
    ],
    types: [Spells.Types.SUMMON],
  },
  {
    additionalEffects: [`Attention : le malus au combat des élémentaires ne s'applique pas aux armes invoquées`],
    cost: 4,
    description: `Invoque magiquement une arme ou une armure.`,
    name: `Invocation d'arme ou d'armure`,
    requirements: [
      `relique (plan ou réplique de l'arme ou de l'armure)`,
      `composant (ferraille)`,
    ],
    types: [Spells.Types.SUMMON],
  },
  {
    cost: 4,
    damage: `6`,
    description: `Invoque magiquement un être de fer, un golem prêt au combat.`,
    name: `Invocation de golem de fer`,
    requirements: [
      `relique (objet de la forme du golem)`,
      `composant (ferraille)`,
    ],
    summon: [
      `Armure : 8`,
      `Toucher : 70 %`,
      `Taille : 160 cm`,
      `Poids : 60 kg`,
    ],
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : inflige Brûlure V pour 3 tours aux personnes qui le traverse`,
      `Surcharge : double l'effet de Brûlure appliqué`,
    ],
    area: `1 m²`,
    cost: 4,
    description: `Invoque magiquement une protection enflammée tellement ardente que les balles fondent au travers, ce qui les empêche de traverser.`,
    name: `Mur de lave`,
    types: [Spells.Types.SUMMON],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.AGILITY} -20, vitesses ÷ 2`,
      `Surcharge : crée un trou dans le sol à la place`,
    ],
    area: `1 m²`,
    cost: 4,
    description: `Scinde la matière du sol alentour afin de rendre le terrain friable, sableux et poussiéreux.`,
    name: `Terrain sableux`,
    requirements: [
      `contact`,
      `sol friable (terre, sable, boue, …)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    cost: 6,
    damage: `10`,
    description: `Invoque magiquement un énorme être de flammes prêt à combattre.`,
    name: `Avatar de flammes`,
    requirements: [`relique (source de feu, briquet, allumettes, ...)`],
    summon: [
      `Insensible aux dégâts physiques`,
      `Toucher : 90 %`,
      `Taille : 180 cm`,
      `Poids : 0 kg`,
    ],
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : gravité ÷ 2 ou gravité x 2`,
      `Surcharge : gravité ÷ 3 ou gravité x 3 à la place`,
    ],
    area: `1 m²`,
    cost: 6,
    description: `Réduit ou augmente l'effet de la gravité dans la zone alentour.`,
    name: `Changement de gravité`,
    requirements: [
      `contact`,
      `composant (morceau de roche)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Surcharge : permet d'invoquer un véhicule moyen, touk-touk ou voiture`],
    cost: 6,
    description: `Invoque magiquement un véhicule léger, trottinette, bicyclette ou moto.`,
    name: `Invocation de véhicule léger`,
    requirements: [
      `relique (plan du véhicule)`,
      `composant (ferraille)`,
    ],
    types: [Spells.Types.SUMMON],
  },
  {
    additionalEffects: [
      `Effet : 60% de chances d'infliger Brûlure Ⅳ pour 6 tours`,
      `Surcharge : 90% de chances d'infliger Brûlure V pour 6 tours à la place`,
    ],
    area: `10 m²`,
    cost: 6,
    damage: `15`,
    description: `Des rocs enflammés se mettent à pleuvoir dans la zone alentour, un mélange de lave en fusion et de pierre volcanique.`,
    name: `Pluie de météores`,
    requirements: [
      `proximité`,
      `composant (morceau de roche)`,
      `relique (source de feu, briquet, allumettes, ...)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : fonctionne sur des objets en tissu, en bois ou en cuir`,
      `Surcharge: fonctionne sur des petits objets en fer ou en métal (les objets vont fondre)`,
    ],
    cost: 6,
    description: `Réduit en cendres des objets, pratique pour déposséder quelqu'un de ses biens.`,
    name: `Réduire à la cendre`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.AGILITY} -50`,
      `Effet : 80% de chances de renverser au sol`,
      `Surcharge : renverse les personnes au sol et elles ne peuvent plus agir`,
    ],
    area: `5 m²`,
    cost: 6,
    description: `Provoque un séisme de magnitude moyenne aux alentours proches.`,
    name: `Séisme`,
    requirements: [
      `contact`,
      `composant (morceau de roche)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 6,
    description: `Referme un trou d'environ 1 m³ (ou 1000 L) et y enferme les personnes éventuellement à l'intérieur. Ces dernières peuvent s'asphyxier et mourir si elles ne parviennent pas à s'échapper.`,
    name: `Tombeau`,
    requirements: [
      `contact`,
      `trou`,
      `composant (morceau de roche)`,
    ],
    types: [Spells.Types.SUMMON],
  },
]);

export default data.map((i) => ({
  ...i,
  schools: [{ name: Spells.School.FIRE_EARTH }],
}));
