import { Monsters, Spells } from 'data/metadata';

const data = [
  {
    armor: 2,
    damage: [`6 + 1d6`, ``, ``],
    hit: [60, 0, 0],
    initiative: 2,
    name: `Homme-rat`,
    skills: [
      { skill: 'Maîtrise', value: `65 %` },
    ],
    speed: 4,
    types: [Monsters.Type.BEAST, Monsters.Type.HUMAN],
  },
  {
    armor: 1,
    attacks: [
      `Applique Radiations II`,
      `40% : applique Poison D pour 6 tours`,
    ],
    damage: [`6 + 1d6`, ``, ``],
    hit: [75, 0, 0],
    name: `Wido`,
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Absorption : la vase s'enroule autour d'une personne et ne peut plus être ciblée, les blessures infligées à la personne sont aussi infligées à la vase, augmente la Fatigue de la personne jusqu'à atteindre le score en Endurance puis elle tombe inconsciente`],
    name: `Vase collante`,
    passives: [Monsters.Skills.Group(`3 à 6 [2 + 1d4]`)],
    speed: 1,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    armor: 2,
    attacks: [
      `Dégâts doublés contre les cyborgs`,
      `Provoque un dysfonctionnement de ce qui est électrique ou électronique`
    ],
    damage: [`4 + 1d8`, ``, ``],
    hit: [65, 0, 0],
    name: `Cyborg rouillé`,
    speed: 1,
    types: [Monsters.Type.BEAST, Monsters.Type.HUMAN],
  },
  {
    abilities: [Monsters.Skills.Charge(`2`, `4`)],
    armor: 2,
    attacks: [
      `Applique Radiations II`,
      `(PM) 40 % : applique Poison D pour 3 tours`
    ],
    damage: [`8 + 1d6`, ``, ``],
    death: [`Nuage de poison: libère un nuage empoisonné qui a 60 % de chances d'appliquer Poison D pour 12 tours`],
    hit: [85, 60, 0],
    initiative: 2,
    name: `Mut-cochon`,
    speed: 3,
    types: [Monsters.Type.BEAST, Monsters.Type.PLANT],
  },
  {
    armor: 2,
    damage: [`selon arme invoquée`, ``, ``],
    hit: [55, 75, 0],
    initiative: 3,
    magic: [{ school: Spells.School.FIRE_EARTH, level: `75 %` }],
    mana: `10`,
    name: `Magicien renégat`,
    skills: [
      { skill: `Sang froid`, value: `0 %` },
    ],
    types: [Monsters.Type.ELEMENTAL],
  },
  {
    abilities: [Monsters.Skills.Saut(`3x1d6`)],
    armor: 3,
    attacks: [`60 % : applique Saignements III permanent`],
    damage: [`8 + 1d6`, ``, ``],
    death: [`Explosion : provoque une détonation à la fin de l'activité cardiaque qui inflige 10 + 1d10 aux alentours`],
    hit: [85, 0, 0],
    initiative: 6,
    name: `Cyber-chien`,
    speed: 4,
    types: [Monsters.Type.BEAST, Monsters.Type.ROBOT],
  },
  {
    abilities: [`"Ultrasons": chaque personne aux alentours a 50 % de chances d'être étourdi pendant 1 tour`],
    armor: 4,
    attacks: [`30 % de chances d'appliquer Poison D et M- pour 6 tours`],
    damage: [`7 + 1d6`, ``, ``],
    hit: [80, 0, 0],
    initiative: 2,
    name: `Mut-fourmi`,
    speed: 2,
    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
  },
  {
    attacks: [
      `Fait fondre les armures en peau, tissu ou cuir`,
      `Applique Brûlure III pendant 1 tour au membre du corps touché, puis Saignements III permanent`
    ],
    hit: [80, 80, 0],
    name: `Vase autonome`,
    passives: [
      Monsters.Skills.Intangible,
      Monsters.Skills.AuraRadioactive('II'),
    ],
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Spores énervantes : libère un nuage de spores qui force les personnes proches à attaquer le Porteur de spores`],
    armor: 1,
    attacks: [`Applique Radiations III`],
    damage: [`7 + 1d6`, ``, ``],
    hit: [85, 0, 0],
    initiative: 2,
    name: `Porteur de spores`,
    passives: [Monsters.Skills.RégénérationRadioactive],
    speed: 3,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Psychose généralisée: les personnes proches deviennent folles et gagnent un point de folie`],
    attacks: [`Applique Radiations III`],
    magic: [
      { school: Spells.School.WATER_NATURE, level: `100 %` },
      { school: Spells.School.SHADOW_DEATH, level: `80 %` },
      { school: Spells.School.MIND, level: `60 %` },
    ],
    mana: `30`,
    name: `Horreur des profondeurs cultiste`,
    show: [
      `80% : gagne un point de psychose`,
      `60% : rend fou pendant 10 minutes`,
    ],
    speed: 2,
    types: [Monsters.Type.MUTANT, Monsters.Type.ELEMENTAL],
  },
  {
    abilities: [Monsters.Skills.Envol(30)],
    armor: 4,
    attacks: [
      `Rend fou pendant 1 heure`,
      `Applique Radiations IV`,
      `Applique Poison M- pour 6 tours`,
      `60% : transmets le Petit Mal`,
      `30% : transmets la Peste Bubonique`,
    ],
    damage: [`6 + 1d6`, ``, ``],
    hit: [90, 0, 0],
    initiative: 4,
    name: `Pestenfer`,
    speed: 4,
    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Regard envoûtant : force la cible à regarder dans les yeux pendant 3 tours, contré avec un test de Volonté`],
    armor: 6,
    attacks: [
      `Applique Radiations IV`,
      `70 % : applique Poison D+ pour 6 tours`,
    ],
    damage: [`8 + 1d6`, ``, ``],
    hit: [95, 0, 0],
    initiative: 3,
    items: [`Arme de corps à corps exotique`],
    name: `Mut-scorpion alpha`,
    passives: [
      `Groupe : se trouve généralement avec un groupe de 2 à 7 Radscorpions [1 + 1d6]`,
      Monsters.Skills.Chef('Radscorpions', 4),
      `Regard pétrifiant : après un tour de contact oculaire, transforme la cible en pierre`,
      `Pas d'armure tronc et tête`
    ],
    speed: 5,
    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
  },
  {
    abilities: [Monsters.Skills.Tremblement],
    armor: 6,
    damage: [`4 + DRN + 1d8`, ``, ``],
    hit: [90, 0, 0],
    magic: [{ school: Spells.School.WATER_NATURE, level: `100 %` }],
    mana: `30`,
    name: `Tréant`,
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.ELEMENTAL],
  },
  {
    abilities: [`Odeur putride : les personnes proches tombent inconscientes si elles échouent un test de Volonté`],
    armor: 2,
    attacks: [`60% : transmets la Putride`],
    damage: [`8 + DRN + 1d8`, ``, ``],
    hit: [75, 0, 0],
    magic: [{ school: Spells.School.SHADOW_DEATH, level: `100 %` }],
    mana: `40`,
    name: `Moréant`,
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.ELEMENTAL],
  },
  {
    abilities: [`Nova de poison : les personnes proches ont 35 % de chances de se voir appliquer Poison D pour 6 tours`],
    attacks: [
      `Applique Radiations IV`,
      `Applique Poison D+ pour 6 tours`
    ],
    damage: [`6 + 1d6`, ``, ``],
    hit: [85, 0, 0],
    name: `Vase mutante`,
    passives: [
      Monsters.Skills.Flasque,
      Monsters.Skills.AuraRadioactive('III'),
      Monsters.Skills.RégénérationRadioactive,
    ],
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    armor: 6,
    attacks: [`60 % : renverse la cible au sol`],
    damage: [`8 + 1d8`, ``, ``],
    hit: [90, 0, 0],
    name: `Techno-Thog sauvage`,
    show: [`80 % : effraie les individus proches`],
    speed: 2,
    types: [Monsters.Type.MUTANT, Monsters.Type.ROBOT],
  },
  {
    armor: 4,
    damage: [`7 + DRN + 2x1d4`, ``, ``],
    hit: [95, 0, 0],
    initiative: 8,
    name: `Écorcheur`,
    passives: [Monsters.Skills.MouvementsErratiques(60)],
    show: [`Effraie les individus proches`],
    speed: 6,
    types: [Monsters.Type.BEAST, Monsters.Type.HUMAN],
  },
  {
    attacks: [
      `Applique Radiations IV`,
      `80 % : applique Poison D pour 6 tours`,
      `60 % : rend fou pendant 3 heures, à la suite de quoi le personnage gagne un point de psychose`,
      `40 % : transmets une maladie, généralement une infection`
    ],
    damage: [`9 + 1d8`, ``, ``],
    death: [
      `Zone radioactive : laisse une flaque au sol qui applique Radiations IV par tour à l'intérieur`,
      `Spores empoisonnées: évacue des spores transparentes qui ont 35 % de chances par tour à les respirer d'appliquer du poison H`
    ],
    hit: [110, 0, 0],
    name: `Marécage ambulant`,
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Spores toxiques : libère un nuage qui applique Poison H aux personnes proches pendant 3 tours`],
    actions: `+1`,
    attacks: [`Applique Saignements IV permanent`],
    damage: [`9 + DRN + 1d4`, ``, ``],
    hit: [120, 0, 0],
    initiative: 8,
    name: `Épouvantail des ténèbres`,
    speed: 4,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    actions: `+1`,
    armor: 5,
    hit: [100, 100, 35],
    initiative: 6,
    items: [
      `Arme quelconque exotique`,
      `Armure de cuir renforcée ou armure assistée, partielle ou complète`
    ],
    magic: [
      { school: Spells.School.FIRE_EARTH, level: `60 %` },
      { school: Spells.School.WIND_THUNDER, level: `80 %` },
      { school: Spells.School.LIGHT_LIFE, level: `80 %` },
      { school: Spells.School.SHADOW_DEATH, level: `60 %` },
    ],
    mana: `40`,
    name: `Exécuteur`,
    speed: 3,
    skills: [
      { skill: `Survie`, value: `80 %` },
      { skill: `Social`, value: `65 %` },
      { skill: `Maîtrise`, value: `70 %` },
    ],
    types: [Monsters.Type.HUMAN, Monsters.Type.ELEMENTAL],
  },
  {
    actions: `+1`,
    armor: 6,
    attacks: [`Applique Brûlure III pour 3 tours`],
    damage: [`7 + DRN + 1d6`, ``, ``],
    hit: [90, 0, 0],
    initiative: 8,
    magic: [
      { school: Spells.School.FIRE_EARTH, level: `90 %` },
      { school: Spells.School.WATER_NATURE, level: `60 %` },
      { school: Spells.School.WIND_THUNDER, level: `60 %` },
    ],
    mana: `40`,
    name: `Mut-cornu`,
    speed: 2,
    types: [Monsters.Type.ELEMENTAL, Monsters.Type.MUTANT],
  },
];

export default data;
