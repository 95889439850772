import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';

import { DataGridDisplay } from 'components/afterworld/data/GridDisplay';
import { Weapon } from 'components/afterworld/data/Weapon';
import weapons from 'data/weapons';
import { Subtype } from 'data/metadata';
import { PageHeading } from 'components/elements';

export function DataWeaponPage() {
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const filteredData = weapons
    .filter((datum) =>
      (!filter || datum.subtype === filter)
      && (!search || datum.name.toLowerCase().includes(search))
    );

  return (
    <>
      <PageHeading>
        Armes
      </PageHeading>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <InputLabel id="afterworld-weapons__list-filter__label">
          Type d&apos;arme
        </InputLabel>

        <Select
          id="afterworld-weapons__list-filter"
          label="Type d'arme"
          labelId="afterworld-weapons__list-filter__label"
          onChange={(e) => { setFilter(e.target.value); }}
          size="small"
          value={filter}
        >
          <MenuItem value=""><em>Aucun filtre</em></MenuItem>
          {Object.values(Subtype.Weapon).map((weaponType) => (
            <MenuItem key={weaponType} value={weaponType}>{weaponType}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <TextField
          id="afterworld-weapons__list-search"
          label="Rechercher l'arme"
          onChange={(e) => { setSearch(e.target.value.toLowerCase()); }}
          size="small"
          value={search}
          variant="outlined"
        />
      </FormControl>

      <DataGridDisplay
        data={filteredData}
        mobileComponent={Weapon}
      />
    </>
  );
}
