import { EffectOperation } from './effects';

export const SkillCategory = Object.freeze({
  Fight: `Combat`,
  Knowledge: `Savoir`,
  Social: `Social`,
  Resistance: `Résistance`,
  Mastery: `Maîtrise`,
});

export class Skill {

  #effects;
  #category;
  #name;

  #spent = 0;
  #experience = 0;
  #specialized = false;

  constructor(effects, category, name) {
    this.#effects = effects;
    this.#category = category;
    this.#name = name;
  }

  get name() {
    return this.#name;
  }

  get category() {
    return this.#category;
  }

  get value() {
    return 20 + this.#spent + this.#experience + (this.#specialized ? 30 : 0);
    // return 20 + this.#spent + this.#experience + (this.#specialized ? 30 : 0) + this.modifiers;
  }

  get spent() {
    return this.#spent;
  }
  set spend(v) {
    this.#spent = parseInt(v, 10);
  }

  get experience() {
    return this.#experience;
  }
  set experience(v) {
    this.#experience = parseInt(v * 2, 10) / 2;
  }

  get specialized() {
    return this.#specialized;
  }
  set specialized(v) {
    this.#specialized = !!v;
  }

  get modifiers() {
    const relatedEffects = this.#effects.filter((e) => (
      (e.type === Skill && e.target === this.#name)
      || (e.type === SkillCategory && e.target === this.#category))
    );
    let mod = relatedEffects.filter((e) => (e.operation === EffectOperation.Additive)).reduce((total, v) => (total + v.value), 0);
    relatedEffects.filter((e) => (e.operation === EffectOperation.Multiplicative)).forEach((effect) => {
      mod *= effect.value;
    });
    return mod;
  }

  static createFullSet(effects) {
    return [
      new Skill(effects, SkillCategory.Fight, `Armes légères`),
      new Skill(effects, SkillCategory.Fight, `Armes lourdes`),
      new Skill(effects, SkillCategory.Fight, `Corps-à-corps`),
      new Skill(effects, SkillCategory.Fight, `Explosifs, Lancer`),
      new Skill(effects, SkillCategory.Knowledge, `Biologie`),
      new Skill(effects, SkillCategory.Knowledge, `Culture`),
      new Skill(effects, SkillCategory.Knowledge, `Ingénierie`),
      new Skill(effects, SkillCategory.Knowledge, `Survie`),
      new Skill(effects, SkillCategory.Social, `Empathie`),
      new Skill(effects, SkillCategory.Social, `Intimidation`),
      new Skill(effects, SkillCategory.Social, `Négociation`),
      new Skill(effects, SkillCategory.Social, `Rhétorique`),
      new Skill(effects, SkillCategory.Resistance, `Concentration`),
      new Skill(effects, SkillCategory.Resistance, `Sang froid`),
      new Skill(effects, SkillCategory.Resistance, `Vigueur`),
      new Skill(effects, SkillCategory.Resistance, `Volonté`),
      new Skill(effects, SkillCategory.Mastery, `Acuité`),
      new Skill(effects, SkillCategory.Mastery, `Agilité`),
      new Skill(effects, SkillCategory.Mastery, `Force`),
      new Skill(effects, SkillCategory.Mastery, `Intelligence`),
    ];
  }

}
