import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    additionalEffects: [
      `Coût collatéral : vieillissement de 20 jours`,
      `Surcharge : porte la brèche à taille humaine mais double l'effet du vieillissement`,
    ],
    cost: 2,
    description: `Crée une brèche ce qui permet de téléporter un petit objet ou encore de frapper avec une attaque de mêlée une personne qui se trouve à distance.`,
    name: `Brèche`,
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Coût collatéral : vieillissement de 10 jours`],
    cost: 2,
    description: `Permet de savoir l'heure dans la journée ou de détecter toute distorsion du temps proche.`,
    name: `Conscience du temps`,
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Coût collatéral : vieillissement de 2 mois`],
    cost: 2,
    description: `Accélère ou ralenti le déroulement de la journée.`,
    name: `Déroulement de la journée`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Coût collatéral : vieillissement de 15 jours`],
    cost: 2,
    description: `Rend à un objet sa gloire d'antan ou le vieillit d'une vingtaine d'années`,
    name: `Temporalité d'un objet`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : vitesse +50 %`,
      `Effet : initiative +20`,
      `Effet : actions +1`,
      `Coût collatéral : vieillissement de 6 mois`,
    ],
    cost: 4,
    description: `Ralentit le déroulement du temps alentour ce qui permet d'agir plus rapidement que les personnes proches.`,
    name: `Ralentissement du temps`,
    requirements: [`composant (échantillon de chaos magique stable)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Coût collatéral : vieillissement de 3 mois`],
    cost: 4,
    description: `Permet de revivre une scène d'environ 15 secondes qui s'est passé au même endroit jusqu'à 20 ans en arrière.`,
    name: `Vision du passé`,
    requirements: [`composant (échantillon de chaos magique stable)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Coût collatéral : vieillissement de 5 ans`],
    cost: 6,
    description: `Ouvre une brèche vers une réalité alternative et permet de s'y rendre ou d'y amener des personnes ou des objets.`,
    name: `Brèche du continuum temporel`,
    requirements: [`composant (fiole de chaos magique stable)`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Coût collatéral : vieillissement de 1 an`],
    cost: 6,
    description: `Revient entre 5 et 15 secondes en arrière dans le temps.`,
    name: `Retour dans le passé`,
    requirements: [`composant (fiole de chaos magique stable)`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Coût collatéral : vieillissement de 2 ans`],
    cost: 6,
    description: `Permet de percevoir ce qu'il va se passer dans les 5 à 15 prochaines secondes.`,
    name: `Vision de l'avenir`,
    requirements: [`composant (fiole de chaos magique stable)`],
    types: [Spells.Types.CAST],
  }, {
    additionalEffects: [
      `Coût collatéral : vieillissement de 10 ans`,
      `Attention : ce sort présente des risques de dégâts létaux très élevés`,
    ],
    area: `8m²`,
    cost: 6,
    description: `Créé une zone d'anomalies temporelles aux alentours. Le déroulement du temps et les lois physiques de l'espace ne s'y appliquent plus, ce qui rend le comportement de toute manière à l'intérieur imprédictible.`,
    name: `Zone de distortion temporelle`,
    requirements: [`composant (fiole de chaos magique stable)`,],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
]);

export default data.map((i) => ({
  ...i,
  schools: [{ name: Spells.School.TIME }],
}));
