import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';

import armors from 'data/armors';
import { Armor } from 'components/afterworld/data/Armor';
import { DataGridDisplay } from 'components/afterworld/data/GridDisplay';
import { PageHeading } from 'components/elements';
import { Subtype } from 'data/metadata';

export function DataArmorPage() {
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const filteredData = armors
    .filter((datum) =>
      (!filter || datum.subtype === filter)
      && (!search || datum.name.toLowerCase().includes(search))
    );

  return (
    <>
      <PageHeading>
        Armures
      </PageHeading>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <InputLabel id="afterworld-armors__list-filter__label">
          Type d&apos;armure
        </InputLabel>

        <Select
          id="afterworld-armors__list-filter"
          label="Type d'armure"
          labelId="afterworld-armors__list-filter__label"
          onChange={(e) => { setFilter(e.target.value); }}
          size="small"
          value={filter}
        >
          <MenuItem value=""><em>Aucun filtre</em></MenuItem>
          {Object.values(Subtype.Armor).map((type) => (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <TextField
          id="afterworld-armors__list-search"
          label="Rechercher l'armure"
          onChange={(e) => { setSearch(e.target.value.toLowerCase()); }}
          size="small"
          value={search}
          variant="outlined"
        />
      </FormControl>

      <DataGridDisplay
        data={filteredData}
        mobileComponent={Armor}
      />
    </>
  );
}
