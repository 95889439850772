
import { Quote, Subtitle, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualPassivesPage() {
  return (
    <>
      <PageHeading>
        Passifs
      </PageHeading>

      <Title>Fonctionnement</Title>

      <Text>
        {`Les passifs ont tous un score de base de 3, sauf l'Armure et l'Initiative qui commencent à 0.`}
      </Text>

      <Text>
        {`Pour augmenter ce score avec des points dépensés, il faut toujours dépenser un point supplémentaire par rapport au nombre de points actuels.`}
      </Text>

      <Text>
        {`Par exemple pour passer de 0 à 1 en Initiative, il faut dépenser 1 point, mais pour passer de 1 à 2, il faut dépenser 2 points, puis 3 points pour passer à 3, et ainsi de suite ...`}
      </Text>

      <Quote>
        {`Plus un score est élevé et plus il est difficile de continuer à le monter.`}
      </Quote>

      <Title>Les passifs disponibles</Title>

      <Subtitle>Armure</Subtitle>

      <Text>
        {`L'armure détermine la capacité du personnage à encaisser des blessures. Le montant du dé de blessures est réduit d'autant que le score en Armure.`}
      </Text>

      <Quote>
        {`Ce score peut aussi être augmenté sur chaque membre du corps selon l'armure portée par le personnage`}
      </Quote>

      <Subtitle>Charge</Subtitle>

      <Text>
        {`Il s'agit de la capacité en kilogrammes que le personnage est capable de porter sans broncher.`}
      </Text>

      <Text>
        {`La surcharge entraîne des malus sur les compétences et réduit la capacité du personnage à se déplacer.`}
      </Text>

      <Text>
        {`Le montant en kilogrammes équivaut à 5x le score en Charge.`}
      </Text>

      <Subtitle>Endurance</Subtitle>

      <Text>
        {`Le score en Endurance représente le seuil maximal de fatigue supportable. Si le nombre de points de Fatigue atteignent ce score, le personnage est fatigué.`}
      </Text>

      <Text>
        {`Ce score détermine aussi la tenue et le souffle du personnage en cas d'efforts comme la course, la nage ou l'escalade.`}
      </Text>

      <Text>
        {`Il influe également sur la résistance aux températures extrêmes, chaudes ou froides, que le personnage pourra rencontrer.`}
      </Text>

      <Subtitle>Initiative</Subtitle>

      <Text>
        {`Le score d'initiative détermine la promptitude à réagir avant les autres en combat. Ce score sera ajouté aux jets d'initiative qui sont fait au début des tours.`}
      </Text>

      <Subtitle>Magie</Subtitle>

      <Text>
        {`Il s'agit de la capacité du personnage à endurer les sorts qu'il lance.`}
      </Text>

      <Text>
        {`Si les points de magie tombent à 0 ou moins, ce score reste à 0. Toutefois la différence est ajouté aux points de psychose et le personnage tombe inconscient.`}
      </Text>

      <Subtitle>Mental</Subtitle>

      <Text>
        {`Chaque blessure risque de faire perdre des points de Mental au personnage.`}
      </Text>

      <Text>
        {`Un personnage qui n'a plus de points en Mental est considéré comme épuisé moralement et les chances de réussir ses actions sont réduites de moitié.`}
      </Text>

      <Text>
        {`Ces points se récupèrent lors de situations particulières : après une nuit de sommeil complète, un repas copieux, une situation relaxante ou divertissante, ...`}
      </Text>
    </>
  );
}
