import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.WILL} -25 face au sommeil`,
      `Effet : ${CHARACTER.SKILLS.MASTERY.ACUITY} -15 (écoute)`,
      `Effet : ${CHARACTER.SKILLS.DEFENSES.FOCUS} -15`,
      `Surcharge : endort la cible`,
    ],
    cost: 2,
    description: `Façonne le vent près d'une personne afin de lui faire entendre une berceuse, ce qui va la rendre somnolente.`,
    name: `Berceuse`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Surcharge : inflige 7 + 1d4 de blessures tranchants`],
    cost: 2,
    description: `Une légère brise tellement fine qu'elle est capable de petites coupures. Pratique pour ouvrir une lettre ou sectionner une bourse.`,
    name: `Brise tranchante`,
    requirements: [`composant (sphère venteuse)`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Surcharge : le vent devient soudain et permet de désarmer une cible`],
    cost: 2,
    description: `Effectue quelques poussées dans l'air afin d'ouvrir ou fermer une porte ou une fenêtre.`,
    name: `Coup de vent`,
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : désactive les appareils électroniques pour 1 tour`,
      `Surcharge : désactive les appareils électroniques pour 12 tours (1 minute)`,
    ],
    cost: 2,
    description: `Crée une légère décharge, assez puissante pour mettre hors d'état de nuire des circuits électroniques.`,
    name: `Décharge électrostatique`,
    requirements: [`composant (sphère orageuse)`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Attention : canaliser un sort ne permet pas de se déplacer naturellement`,
      `Surcharge : plus aucun bruit n'est transporté dans la zone environnante, l'effet est très perturbant`,
    ],
    cost: 2,
    description: `Réduit les bruits produits par les pas d'une personne.`,
    name: `Étouffer les sons`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Surcharge : permet de ramener un objet distant à portée de main`],
    cost: 2,
    description: `Transporte les odeurs, gaz ou poisons dans l'air vers une personne ou un lieu.`,
    name: `Porté par le vent`,
    range: `10m`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Surcharge : assourdi la cible, ${CHARACTER.SKILLS.MASTERY.ACUITY} -30 (écoute)`],
    cost: 2,
    description: `Permet de chuchoter des messages à l'oreille d'une personne à condition de canaliser le sort assez longtemps.`,
    name: `Sons illusoires`,
    range: `5 m`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 1,
    description: `Invoque magiquement une petite boule de vent ou d'orage à proximité. Cette dernière reste statique et sert de composant pour lancer certains sorts.`,
    name: `Sphère venteuse ou orageuse`,
    types: [Spells.Types.SUMMON],
  },
  {
    area: `1 m²`,
    cost: 4,
    description: `Crée une zone de vide autour d'une personne. En l'absence totale d'air, cette dernière va finir par suffoquer si elle nécessite de l'oxygène pour vivre.`,
    name: `Bulle de vide`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 4,
    description: `Crée une zone d'air autour d'une personne, ce qui protège des autres fluides. Utile pour se protéger temporairement des gaz et poisons aéroportés ou pour respirer sous l'eau.`,
    name: `Bulle d'air`,
    requirements: [`composant (sphère venteuse)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 4,
    description: `Charge un objet positivement ou négativement. Ce dernier sera attiré par les autres objets qui sont de même charge sera repoussé par les objets de charge inverse.`,
    name: `Charge électrique`,
    requirements: [`composant (sphère orageuse)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : dégâts de chute ÷ 2`,
      `Surcharge : dégâts de chute ÷ 3`,
    ],
    cost: 4,
    description: `Augmente la résistance de l'air afin d'accroître la force de frottement et de réduire une chute.`,
    name: `Chute lente`,
    requirements: [
      `relique (plume)`,
      `composant (sphère venteuse)`,
    ],
    types: [Spells.Types.CHANNEL],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.ACUITY} -30 (écoute) pour 3 tours`,
      `Surcharge : ${CHARACTER.SKILLS.MASTERY.ACUITY} -50 (écoute) pour 12 tours (1 minute)`,
    ],
    area: `10 m²`,
    cost: 4,
    description: `Crée une gigantesque explosion sonique dont la puissance peut briser les vitres.`,
    name: `Explosion sonique`,
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Surcharge : permet de faire voler la cible sur plusieurs mètres de hauteur, attention à la chute !`],
    cost: 4,
    description: `Fait légèrement léviter la personne, ce qui lui évite de faire du bruit en marchant ainsi que de déclencher les pièges et mines au sol. Cependant la cible ne peut plus se déplacer d'elle-même.`,
    name: `Lévitation`,
    requirements: [
      `contact`,
      `relique (plume)`,
      `composant (sphère venteuse)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : renverse une personne au sol`,
      `Surcharge : après un test de Vigueur échoué, la personne tombe inconsciente pour 1d4 tours`,
    ],
    cost: 4,
    description: `Provoque une violente bourrasque de vent sec et chaud vers une personne, ce qui va la renverser au sol.`,
    name: `Sirocco`,
    requirements: [`composant (sphère venteuse)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : le lanceur subit la moitié du montant des blessures sur ses mains`,
      `Effet : les blessures sont augmentées à tour de canalisation (4 à la première incantation, 8 après un tour de canalisation, puis 12, ...)`,
      `Surcharge : les blessures sont doublées à chaque tour de canalisation à la place (commence à 4, puis 8, puis 16, ...)`,
    ],
    cost: 6,
    damage: `4`,
    description: `Des arcs électriques jaillissent des mains du lanceur vers jusqu'à 3 personnes qui seront brûlés par l'électrocution. Chaque tour de canalisation augmente l'effet des blessures.`,
    name: `Arcs électriques`,
    requirements: [`composant (sphère orageuse)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    area: `4 m³`,
    cost: 6,
    description: `Crée des courants aériens dont la présence est connectée, ce qui permet d'explorer les moindres recoins d'une pièce et de détecter l'existence de passages secrets.`,
    name: `Cartographie de la pièce`,
    requirements: [`composant (sphère venteuse)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 6,
    description: `Amplifie des sons infiniment petits afin de les rendre audibles de nouveau et d'entendre une scène du passé dans un endroit clos, pour savoir ce qui s'y est déroulé.`,
    name: `Échos du passé`,
    requirements: [
      `contact`,
      `lieu clos`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : 50% de chances de paralyser la personne pour 1 tour`,
      `Surcharge : augmente les blessures à 10 + 1d10 et paralyse pendant 3 tours`,
    ],
    cost: 6,
    damage: `6 + 1d6`,
    description: `Charge la pression atmosphérique et la température de l'air autour d'une personne afin de la prendre dans une intense décharge électrique.`,
    name: `Électrocution`,
    requirements: [`composant (sphère orageuse)`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Surcharge : augmente les blessures à 10 + 1d10`],
    area: `10 m²`,
    cost: 6,
    damage: `6 + 1d6`,
    description: `Invoque un orage dans la zone alentour, ce qui perturbe les appareils électroniques proches et peut frapper par la foudre les personnes dans les environs.`,
    name: `Orage`,
    requirements: [`composant (sphère orageuse)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : vitesse des projectiles ÷ 2`,
      `Surcharge : vitesse des projectiles ÷ 10 à la place`,
    ],
    area: `10 m²`,
    cost: 6,
    description: `Créé une zone alentour qui ralentit les projectiles à l'intérieur.`,
    name: `Ralentissement des projectiles`,
    requirements: [`composant (sphère venteuse)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Surcharge : double la hauteur et la puissance de la tornade`],
    cost: 6,
    description: `Invoque magiquement une petite tornade qui a une chance d'emporter les personnes à l'intérieur.`,
    high: `8 mètres`,
    name: `Tornade`,
    requirements: [`composant (sphère venteuse)`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
]);

export default data.map((i) => ({
  ...i,
  schools: [{ name: Spells.School.WIND_THUNDER }],
}));
