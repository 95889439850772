import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { PageHeading } from 'components/elements';
import { PageRootContainer } from 'layout/PageRootContainer';
import { useCharactersStore } from 'store';

export function CharacterDetailsPage() {
  const params = useParams();
  const characterId = params['*'].split('/').pop();
  const character = useCharactersStore((state) => state.characters[characterId]);

  return (
    <PageRootContainer target="/">
      <PageHeading backButton={(<Link to="/characters">
        <FontAwesomeIcon icon={['fa', 'arrow-left']} />
      </Link>)}>
        {character.name}
      </PageHeading>
      <Outlet />
    </PageRootContainer>
  );
}
