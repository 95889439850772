import { names } from 'data/character';

import { GiftSlot } from './gifts';
import { Magic } from './magic';
import { Passive } from './passives';
import { Skill } from './skills';

export class Character {

  #id;
  #name;
  #level = 1;
  #race;
  #sleep;
  #mainHand;
  #descriptionPhysical;
  #descriptionPsychological;
  #objectiveShortTerm;
  #objectiveLongTerm;
  #madnesses;

  #pointsLuck;
  #pointsDivine;
  #pointsPsychose;
  #pointsFatigue;

  #skillSet;
  #magicSet;
  #passiveSet;
  #giftsSlots;
  #effects;

  constructor() {
    if (!this.#id) {
      this.#id = crypto.randomUUID();
    }
    if (!this.#name) {
      this.#name = names[Math.floor(Math.random() * names.length)].name;
    }
    this.#effects = new Set();
    this.#skillSet = Skill.createFullSet(this.getEffects());
    this.#magicSet = Magic.createFullSet(this.getEffects());
    this.#passiveSet = Passive.createFullSet(this.getEffects());
    this.#giftsSlots = GiftSlot.createFullSet(this.getEffects());
  }

  get name() {
    return this.#name;
  }
  get skills() {
    return this.#skillSet;
  }
  get magic() {
    return this.#magicSet;
  }
  get passives() {
    return this.#passiveSet;
  }
  get gifts() {
    return this.#giftsSlots;
  }

  getId() {
    return this.#id;
  }
  getEffects() {
    return this.#effects;
  }

  get level() {
    return this.#level;
  }
  set level(v) {
    this.#level = parseInt(v, 10);
  }

  get skillPointsAvailable() {
    return (this.#level + 1) * 30;
  }
  get skillPointsSpent() {
    return this.#skillSet.reduce((total, v) => (total + v.spent), 0);
  }

  get magicPointsAvailable() {
    return (this.#level + 1) * 15;
  }
  get magicPointsSpent() {
    return this.#magicSet.reduce((total, v) => (total + v.spent), 0);
  }

  get passivePointsAvailable() {
    return (this.#level + 1) * 3;
  }
  get passivePointsSpent() {
    return this.#passiveSet.reduce((total, v) => (total + v.spent), 0);
  }

  isLegal() {
    if (this.skillPointsSpent > this.skillPointsAvailable) {
      throw new Error(`Trop de points de compétences dépensés.`);
    }

    if (this.magicPointsSpent > this.magicPointsAvailable) {
      throw new Error(`Trop de points de magie dépensés.`);
    }

    if (this.passivePointsSpent > this.passivePointsAvailable) {
      throw new Error(`Trop de points de passifs dépensés.`);
    }

    return true;
  }
}
