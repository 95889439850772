import { Monsters } from 'data/metadata';

const data = [
  {
    armor: 4,
    damage: [`4 + 1d6`, ``, ``],
    hit: [70, 0, 0],
    name: `Cervrobot`,
    passives: [`Pas d'armure à la tête`],
    speed: 2,
  },
  {
    armor: 5,
    damage: [`6 + 1d8`, ``, ``],
    hit: [70, 40, 0],
    initiative: 2,
    name: `Radiobot`,
    passives: [Monsters.Skills.Virevoltant(40)],
    speed: 1,
  },
  {
    armor: 2,
    damage: [`5 + 1x4`, ``, ``],
    hit: [70, 0, 0],
    initiative: 5,
    name: `Spiderbot`,
    passives: [Monsters.Skills.MouvementsErratiques(50)],
    speed: 4,
  },
  {
    armor: 4,
    hit: [50, 80, 60],
    initiative: 4,
    items: [
      `Arme légère à énergie simple`,
      `Armure de tissu ou de cuir complète`
    ],
    name: `Cyborg sauvage`,
    speed: 3,
    skills: [
      { skill: `Sciences`, value: `60 %` },
    ],
  },
  {
    armor: 6,
    attacks: [`30% : paralyse pour 3 tours`],
    damage: [`9 + DRN + 1d6`, ``, ``],
    hit: [80, 40, 0],
    initiative: 2,
    name: `Protectoron`,
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Alarme],
    armor: 3,
    damage: [`7 + 1d6`, ``, ``],
    hit: [85, 55, 0],
    name: `Sentinelle`,
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Feinte],
    armor: 5,
    damage: [`9 + 1d8`, ``, ``],
    hit: [115, 80, 35],
    initiative: 10,
    name: `Tourelle Gunny III`,
  },
  {
    abilities: [
      Monsters.Skills.Feinte,
      Monsters.Skills.Surcharge,
    ],
    armor: 5,
    attacks: [
      `Transmets une maladie`,
      `(PM) Par balayage`,
    ],
    damage: [`7 + 1d8`, ``, ``],
    hit: [90, 55, 0],
    items: [`Arme "Minigun"`],
    name: `Hammut 400 Tueur d'armée`,
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Surcharge],
    armor: 7,
    damage: [`7 + 1d8`, ``, ``],
    hit: [90, 70, 0],
    initiative: 2,
    items: [
      `Arme "Minigun"`,
      `Arme "Lance-grenades"`,
    ],
    name: `Prototype KT-25 de guerre`,
    speed: `3 (course 12)`,
  },
  {
    abilities: [
      Monsters.Skills.Feinte,
      `Impulsion EM : désactive tout mécanisme incluant de l'électronique dans la zone touchée et inflige 10 + 1d10 aux robots`
    ],
    armor: 7,
    damage: [`9 + 1d8`, ``, ``],
    hit: [90, 70, 0],
    initiative: 8,
    name: `Tourelle Burn VII`,
  },
  {
    abilities: [Monsters.Skills.Alarme],
    armor: 10,
    attacks: [`(PC): applique Feu III pour 6 tours`],
    damage: [`8 + 1d8`, `6 + 1d6`, ``],
    death: [`Explosion : inflige 10 + 1d10 blessures`],
    hit: [90, 75, 0],
    initiative: 4,
    name: `M. Gunny`,
    speed: 2,
  },
  {
    abilities: [
      `Cisaille : une attaque précise qui inflige moitié moins de dégâts mais ignore la moitié de l'armure`,
      Monsters.Skills.Alarme,
    ],
    armor: 10,
    attacks: [`Applique Saignements III permanent`],
    damage: [`9 + 2x1d4`, ``, ``],
    hit: [110],
    initiative: 6,
    name: `M. Saw`,
    speed: 3,
  },
  {
    damage: [`selon arme`, ``, ``],
    death: [`Explosion: provoque une auto-destruction qui inflige 12 + 1d10 blessures`],
    hit: [85, 55, 0],
    initiative: 2,
    items: [`Arme de corps à corps ou légère, simple ou exotique`],
    name: `Exo-squelette Y-17`,
    speed: 3,
  },
  {
    actions: `+1`,
    armor: 10,
    damage: [`selon arme`, ``, ``],
    hit: [110, 80, 0],
    initiative: 15,
    items: [
      `Arme de corps-à-corps exotique`,
      `Armure en cuir renforcé complète`,
      `Armure assistée exotique`
    ],
    name: `Gamme YT-0 Assassin`,
    passives: [
      Monsters.Skills.DoubleEsquive,
      `Analyse : reconnaît le membre du groupe à attaquer en premier et quelle partie du corps viser`,
      `Trajectoires assistées : capable de prévisualiser les trajectoires des armes à distance et de les esquiver`,
    ],
    skills: [
      { skill: `Maîtrise`, value: `90 %` }
    ],
    speed: 4,
  },
  {
    actions: `+1`,
    armor: 10,
    damage: [`11 + DRN + 1d6`, ``, ``],
    hit: [120, 0, 0],
    initiative: 10,
    name: `Gamme YT-0 Bourreau`,
    passives: [
      `Aura intimidante : les personnes proches subissent l'état "Fasciné" à chaque tour`,
      `Attaques horrifiques : les personnes qui témoignent des dégâts d'une attaque gagnent un point de psychose`,
    ],
    speed: 4,
  },
  {
    abilities: [Monsters.Skills.Charge(`2`, `4`)],
    armor: 12,
    damage: [`13 + DRN + 1d8`, ``, ``],
    hit: [120, 0, 0],
    name: `Gamme YT-0 Biggy Piggy`,
    passives: [`Attaques horrifiques : les personnes qui témoignent des dégâts d'une attaque gagnent un point de psychose`],
    show: [`60% : effraie les personnes proches`],
    speed: 3,
  },
  {
    actions: `+1`,
    armor: 10,
    damage: [`5 + DRN + 1d4 (mains nues)`, `9 + DRN + 1d6 (mitraillette)`, `11 + DRN + 1d4 (sniper)`],
    hit: [80, 55, 90],
    initiative: 8,
    name: `Gamme YT-0 Assemblage T-26`,
    speed: 4,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.ROBOT],
}));
