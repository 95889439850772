import { useParams } from 'react-router-dom';

import { Table, Title } from 'components/semantic';
import { useCharactersStore } from 'store';

export function CharacterGeneralPage() {
  const params = useParams();
  const characterId = params['*'].split('/').pop();
  const character = useCharactersStore((state) => state.characters[characterId]);

  const characterData = [
    [`Nom`, character.name],
    [`Niveau`, character.level],
    [`Race`, character.race],
    [`Sommeil`, character.sleep],
    [`Main d'usage`, character.mainHand],
  ];

  const characterRolePlayData = [
    [`Description physique`, character.descriptionPhysical],
    [`Description psychologique`, character.descriptionPsychological],
    [`Objectif court terme`, character.objectiveShortTerm],
    [`Objectif long terme`, character.objectiveLongTerm],
    [`Folies`, character.madnesses],
  ];

  const characterPointsData = [
    [`Points de chance`, character.pointsLuck],
    [`Points divins`, character.pointsDivine],
    [`Points de psychose`, character.pointsPsychose],
    [`Points de fatigue`, character.pointsFatigue],
  ];

  return (
    <>
      <Title>{`Personnage`}</Title>
      <Table data={characterData}></Table>

      <Title>{`Caractère`}</Title>
      <Table data={characterRolePlayData}></Table>

      <Title>{`Points`}</Title>
      <Table data={characterPointsData}></Table>
    </>
  );
}
