
import { Image, Quote, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function TabletopRPGPage() {
  return (
    <>
      <PageHeading>
        Jeu de role
      </PageHeading>

      <Title>Un jeu de role</Title>

      <Text>
        {`Une partie de jeu de rôle classique se déroule en groupe autour d'une table. Une personne endosse le rôle du « Maître du jeu », dit « MJ », tandis que les autres personnes sont les joueurs.
        Le MJ imagine le monde, le fait vivre et le partage aux joueurs. Ces derniers peuvent interagir par le biais d'un personnage qu'ils incarnent. Ils vivent cette expérience grâce à cet individu fictif, ils sont dans sa peau et dans sa tête.
        Pour jouer, il faut au groupe: du papier, de quoi écrire, les documents de ce jeu de rôle ainsi que des dés.`}
      </Text>

      <Image size="small" src="/images/intro/dices.webp" />

      <Text>
        {`Pour ce jeu, chaque personne doit se munir d'un set de dés complet (4 faces, 6 faces, 8 faces, 2x 10 faces, 12 faces et 20 faces). Ce genre de dés se trouve dans le commerce, auprès des boutiques de jeux de société.`}
      </Text>

      <Quote>{`Les dés ajoutent de l'aléatoire au jeu`}</Quote>

      <Text>
        {`Pour simplifier, voici les notations à adopter :`}
      </Text>

      <Text>{`« un dé à 6 faces » : 1d6`}</Text>
      <Text>{`« 3 dés à 4 faces » : 3d4`}</Text>
      <Text>{`« un dé à 8 faces, multiplié par 2 » : 2x1d8`}</Text>

      <Text>
        {`Lorsque « 1d100 » est demandé, il faut alors lancer les deux dés à 10 faces, utiliser l'un pour les dizaines et l'autre pour les unités.`}
      </Text>

      <Quote>
        {`Le dé à 100 faces est le plus utilisé dans ce jeu de rôle.`}
      </Quote>

      <Title>Les personnages</Title>

      <Text>
        {`Le personnage est le pilier central du jeu, il s'agit des individus qu'incarnent les joueurs. C'est donc naturellement autour de lui que s'axe la majeure partie des règles.`}
      </Text>

      <Text>
        {`Il dispose de nombreux éléments qui permettent de le décrire dans ses capacités physiques et mentales. Tous ces éléments seront détaillés dans le manuel du joueur.`}
      </Text>

      <Image size="medium" src="/images/intro/town.webp" />

      <Text>
        {`Au-delà des règles, le détail de jeu probablement le plus important à retenir est le suivant : il faut incarner le personnage.
        C'est ce qui s'appelle dans le jargon, le RP ou le Role Play, la capacité à jouer correctement les actions du personnage en suivant sa psychologie et ses habitudes.
        C'est la chance de pouvoir jouer un rôle différent de celui du quotidien, il faut en profiter !`}
      </Text>

      <Image size="medium" src="/images/intro/character.webp" />

      <Title>Contrat social et ludique</Title>

      <Text>
        {`Il s'agit là de règles de bonne conduite généralement acceptée tacitement, mais il semble préférable d'en parler tout de même.`}
      </Text>

      <Text>
        {`L'aventure dans ce type de jeu peut s'avérer très variée selon les jeux et les MJs et souvent riche en émotions, positives ou négatives. La satisfaction de l'accomplissement ou la frustration de l'échec sont des parties intégrantes de la narration pour animer et faire vivre le monde autour des personnages.`}
      </Text>

      <Text>
        {`Un principe fondamental de participer à un jeu de rôle reste de s'amuser. Si d'aventure vous rencontrez des situations malaisantes ou inconfortable, il est nécessaire d'en parler aux personnes autour de vous. Vous n'êtes pas forcé de vivre des situations qui vous déplaisent.`}
      </Text>

      <Text>
        {`C'est le cas pour l'ensemble des participants, ménagez donc aussi vos actions qui pourraient nuire à l'expérience de jeu d'autrui. Pour que chacun tire plaisir d'une session de jeu, l'histoire doit être écrite conjointement par l'ensemble des participants. Pour ce faire, il faut également que chacun garde en tête que les actions menées sont celles des personnages et non des joueurs. Ne tenez pas rigueur à quelqu'un de ses actions dans une partie, ni ne jugez quelqu'un par ce qu'il fait faire à son personnage. C'est le but du jeu d'incarner des personnalités différentes de soit.`}
      </Text>

      <Text>
        {`Enfin, n'oubliez jamais de communiquer en dehors du jeu. Participer à du jeu de rôle sous la contrainte de la pression sociale ou ne pas pouvoir jouer faute d'un joueur absent sont des frustrations qui peuvent être évitées avec de la communication.`}
      </Text>
    </>
  );
}
