import { Subtitle, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function UniversePlansPage() {
  return (
    <>
      <PageHeading>
        Les plans
      </PageHeading>

      <Title>{`La cosmologie des plans`}</Title>

      <Text>
        {`Tout comme le temps représente une quatrième dimension difficilement apprehensible, les plans d'existence peuvent être perçus comme une dimension supplémentaire.`}
      </Text>

      <Subtitle>{`L'entropie`}</Subtitle>

      <Text>
        {`L'ensemble des plans baignent dans une soupe cosmologique nommée l'Entropie. Aucune loi d'existence n'y prend forme et c'est un non-lieu où s'y trouve uniquement le chaos, la vie n'y est pas possible sous aucune forme.`}
      </Text>

      <Text>
        {`Certains plans sont matériels et la vie qui s'y trouve prend une manifestation physique, tandis que d'autres sont immatériels et les lois qui les régissent permettent à des formes de vie d'exister sans manifestation physique.`}
      </Text>

      <Subtitle>{`Le plan des vivants`}</Subtitle>

      <Text>
        {`Le plan dans lequel les gens vivent et la majeure partie des histoires se déroulent est le plan des vivants, mais il en existe d'autres. Chaque plan d'existence est régi par des lois qui lui sont propres. Par exemple dans le plan des vivants, des lois physiques existent comme la gravité. Les êtres qui peuplent ce plan ont une matérialité physique et sont soumis aux lois du plan.`}
      </Text>

      <Text>
        {`Le plan des vivants est bien connu, on y trouve la terre, mais également l'ensemble des autres planètes de la galaxie.`}
      </Text>

      <Text>
        {`Un personnage qui n'est pas doué de compétences en transplanage et qui passe trop de temps hors de son plan d'origine subit des conséquences. S'il s'agit d'un plan matériel, il gagne des points de Psychose, mais s'il s'agit d'un plan immatériel, il va graduellement perdre des points de compétences et des passifs au fil des jours, de manière définitive.`}
      </Text>

      <Title>{`Les plans proches`}</Title>

      <Subtitle>{`Plan des morts`}</Subtitle>

      <Text>
        {`Directement lié au plan des vivants, ce plan est matériel. Il s'agit de là où vont les formes de vies qui décèdent.`}
      </Text>

      <Subtitle>{`Plan élémentaire`}</Subtitle>

      <Text>
        {`Un plan d'existence matériel où les formes de vie sont toutes issues d'un élément de la magie.`}
      </Text>

      <Subtitle>{`Plan de l'ombre`}</Subtitle>

      <Text>
        {`Ce plan est immatériel.`}
      </Text>

      <Title>{`Les plans éloignés`}</Title>

      <Subtitle>{`Plan astral`}</Subtitle>

      <Text>
        {`Ce plan est immatériel.`}
      </Text>

      <Subtitle>{`Plan des dieux`}</Subtitle>

      <Text>
        {`Il s'agit du plan d'existence des êtres divins. C'est un plan matériel.`}
      </Text>

      <Subtitle>{`Plan des esprits`}</Subtitle>

      <Text>
        {`Ce plan est immatériel.`}
      </Text>

      <Title>{`Les plans inconnus`}</Title>

      <Text>
        {`Au delà des plans présentés ici, d'autres existent mais sont trop éloignés pour que quiconque ait pu s'y aventurer et revenir. Un parfait objectif pour des aventuriers ambitieux !`}
      </Text>

    </>
  );
}
