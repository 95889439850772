import { EffectOperation } from './effects';

export class Passive {

  #effects;
  #name;
  #factor;

  #spent = 0;

  constructor(effects, name, factor) {
    this.#effects = effects;
    this.#name = name;
    this.#factor = factor ? factor : (v) => (v);
  }

  get effective() {
    return Math.floor((-1 + Math.sqrt(1 + 8 * this.#spent)) / 2);
  }
  get value() {
    return this.#factor(3 + this.effective + this.modifiers);
  }

  get spent() {
    return this.#spent;
  }
  set spend(v) {
    this.#spent = parseInt(v, 10);
  }

  get modifiers() {
    const relatedEffects = this.#effects.filter((e) => (e.type === Passive && e.target === this.#name));
    let mod = relatedEffects.filter((e) => (e.operation === EffectOperation.Additive)).reduce((total, v) => (total + v.value), 0);
    relatedEffects.filter((e) => (e.operation === EffectOperation.Multiplicative)).forEach((effect) => {
      mod *= effect.value;
    });
    return mod;
  }

  static createFullSet(effects) {
    return [
      new Passive(effects, `Armure`),
      new Passive(effects, `Charge`, (v) => (v * 5)),
      new Passive(effects, `Endurance`),
      new Passive(effects, `Initiative`),
      new Passive(effects, `Magie`),
      new Passive(effects, `Mental`),
    ];
  }

}
