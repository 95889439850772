import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';

import { BestiaryPage } from 'pages/BestiaryPage';
import { CharacterDetailsPage } from 'pages/characters/CharacterDetailsPage';
import { CharacterGeneralPage } from 'pages/characters/CharacterGeneralPage';
import { CharacterGeneratorPage } from 'pages/generators/CharacterGeneratorPage';
import { CharacterGiftsPage } from 'pages/characters/CharacterGiftsPage';
import { CharacterItemsPage } from 'pages/characters/CharacterItemsPage';
import { CharacterMagicPage } from 'pages/characters/CharacterMagicPage';
import { CharacterSkillsPage } from 'pages/characters/CharacterSkillsPage';
import { CharactersListPage } from 'pages/characters/CharactersListPage';
import { DataArmorPage } from 'pages/data/DataArmorPage';
import { DataGiftPage } from 'pages/data/DataGiftPage';
import { DataItemPage } from 'pages/data/DataItemPage';
import { DataSpellPage } from 'pages/data/DataSpellPage';
import { DataWeaponPage } from 'pages/data/DataWeaponPage';
import { HomePage } from 'pages/HomePage';
import { ManualCharacterPage } from 'pages/manual/character/ManualCharacterPage';
import { ManualDamagePage } from 'pages/manual/fight/ManualDamagePage';
import { ManualGlossaryPage } from 'pages/manual/introduction/ManualGlossaryPage';
import { ManualDrugsPage } from 'pages/manual/other/ManualDrugsPage';
import { ManualEnvironmentPage } from 'pages/manual/other/ManualEnvironmentPage';
import { ManualIntroductionPage } from 'pages/manual/introduction/ManualIntroductionPage';
import { ManualMadnessPage } from 'pages/manual/other/ManualMadnessPage';
import { ManualMagicPage } from 'pages/manual/character/ManualMagicPage';
import { ManualMeleePage } from 'pages/manual/fight/ManualMeleePage';
import { ManualModifiersPage } from 'pages/manual/fight/ManualModifiersPage';
import { ManualPassivesPage } from 'pages/manual/character/ManualPassivesPage';
import { ManualRacePage } from 'pages/manual/character/ManualRacePage';
import { ManualRangedPage } from 'pages/manual/fight/ManualRangedPage';
import { ManualRoundPage } from 'pages/manual/fight/ManualRoundPage';
import { ManualSkillsPage } from 'pages/manual/character/ManualSkillsPage';
import { ManualSpecialPage } from 'pages/manual/fight/ManualSpecialPage';
import { ManualStatesPage } from 'pages/manual/other/ManualStatesPage';
import { ManualStatisticsPage } from 'pages/manual/character/ManualStatisticsPage';
import { MonsterPage } from 'pages/MonsterPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { PageRootLayout } from 'layout/PageRootLayout';
import { SectionLayout } from 'layout/SectionLayout';
import { ShopGeneratorPage } from 'pages/generators/ShopGeneratorPage';
import { TabletopRPGPage } from 'pages/intro/TabletopRPGPage';
import { UniverseJobsPage } from 'pages/universe/UniverseJobsPage';
import { UniversePlansPage } from 'pages/universe/UniversePlansPage';
import { UniversePricesPage } from 'pages/universe/UniversePricesPage';
import { WarningPage } from 'pages/universe/WarningPage';

export function PageRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<PageRootLayout />}>
          <Route index element={<HomePage />} />

          <Route path="intro/" element={<SectionLayout />}>
            <Route index element={<TabletopRPGPage />} />
          </Route>

          <Route path="universe/" element={<SectionLayout />}>
            <Route index element={<WarningPage />} />
            <Route path="daily/jobs" element={<UniverseJobsPage />} />
            <Route path="daily/prices" element={<UniversePricesPage />} />
            <Route path="location/plans" element={<UniversePlansPage />} />
          </Route>

          <Route path="manual/" element={<SectionLayout />}>
            <Route index element={<ManualIntroductionPage />} />
            <Route path="glossary" element={<ManualGlossaryPage />} />

            <Route path="character/">
              <Route path="creation" element={<ManualCharacterPage />} />
              <Route path="race" element={<ManualRacePage />} />
              <Route path="skills" element={<ManualSkillsPage />} />
              <Route path="passives" element={<ManualPassivesPage />} />
              <Route path="statistics" element={<ManualStatisticsPage />} />
              <Route path="magic" element={<ManualMagicPage />} />
            </Route>

            <Route path="fight/">
              <Route path="melee" element={<ManualMeleePage />} />
              <Route path="modifiers" element={<ManualModifiersPage />} />
              <Route path="ranged" element={<ManualRangedPage />} />
              <Route path="round" element={<ManualRoundPage />} />
              <Route path="special" element={<ManualSpecialPage />} />
              <Route path="damage" element={<ManualDamagePage />} />
            </Route>

            <Route path="other/">
              <Route path="environment" element={<ManualEnvironmentPage />} />
              <Route path="drugs" element={<ManualDrugsPage />} />
              <Route path="states" element={<ManualStatesPage />} />
              <Route path="madness" element={<ManualMadnessPage />} />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="data/" element={<SectionLayout />}>
            <Route path="gifts" element={<DataGiftPage />} />
            <Route path="spells" element={<DataSpellPage />} />
            <Route path="items" element={<DataItemPage />} />
            <Route path="weapons" element={<DataWeaponPage />} />
            <Route path="armors" element={<DataArmorPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="generators/" element={<SectionLayout />}>
            <Route path="character" element={<CharacterGeneratorPage />} />
            <Route path="shop" element={<ShopGeneratorPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="bestiary/">
            <Route index element={<BestiaryPage />} />
            <Route path="*" element={<MonsterPage />} />
          </Route>

          <Route path="characters" element={<CharactersListPage />} />

          <Route path="character" element={<CharacterDetailsPage />}>
            <Route path="general/*" element={<CharacterGeneralPage />} />
            <Route path="gifts/*" element={<CharacterGiftsPage />} />
            <Route path="items/*" element={<CharacterItemsPage />} />
            <Route path="magic/*" element={<CharacterMagicPage />} />
            <Route path="skills/*" element={<CharacterSkillsPage />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
          {/* <Route path=":teamId" element={<Team />} /> */}
        </Route>
      </Routes>
    </AnimatePresence>
  );
}
