
import { Subtitle, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function UniversePricesPage() {
  return (
    <>
      <PageHeading>
        Les prix
      </PageHeading>

      <Title>{`Test`}</Title>

      <Subtitle>{`Test`}</Subtitle>

      <Text>
        {`La magie de l'eau est paisible et douce, mais il faut se méfier de l'eau qui dort. Elle peut devenir tumultueuse et houleuse au premier ordre.`}
      </Text>
    </>
  );
}
