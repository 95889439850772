
import { List, Quote, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function WarningPage() {
  return (
    <>
      <PageHeading>
        {`L'univers`}
      </PageHeading>

      <Title>Mise en garde</Title>

      <Text>
        {`Cette section présente l'univers du jeu. Il paraît impératif d'exprimer certaines mises en garde.`}
      </Text>

      <List data={[
        'Consulter ces pages en tant que joueur peut enseigner un savoir non souhaité',
        'Les informations apprises ici ne sont pas connues de votre personnage',
        `Il ne faut pas avoir d'attentes lors du jeu selon les informations ici présentes`
      ]}></List>

      <Text>
        {`Ce qu'il faut garder en tête, c'est qu'il existe une différence entre le joueur et le personnage.`}
      </Text>

      <Text>
        {`Si un lecteur et joueur doute de ses capacités à savoir faire la distinction entre les deux lors de son jeu, il n'est pas recommandé de poursuivre la lecture de cette section.`}
      </Text>

      <Quote>
        {`Un personnage qui n'est pas au courant des informations dévoilées ici ne peut donc pas en faire ses objectifs`}
      </Quote>
    </>
  );
}
