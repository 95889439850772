import { EffectOperation } from './effects';

export class Magic {

  #effects;
  #name;

  #spent = 0;
  #experience = 0;
  #specialized = false;

  constructor(effects, name) {
    this.#effects = effects;
    this.#name = name;
  }

  get name() {
    return this.#name;
  }

  get value() {
    return this.#spent + this.#experience + (this.#specialized ? 30 : 0);
    // return this.#spent + this.#experience + this.#specialized ? 30 : 0 + this.modifiers;
  }

  get spent() {
    return this.#spent;
  }
  set spend(v) {
    this.#spent = parseInt(v, 10);
  }

  get experience() {
    return this.#experience;
  }
  set experience(v) {
    this.#experience = parseInt(v * 2, 10) / 2;
  }

  get specialized() {
    return this.#specialized;
  }
  set specialized(v) {
    this.#specialized = !!v;
  }

  get modifiers() {
    const relatedEffects = this.#effects.filter((e) => (e.type === Magic && e.target === this.#name));
    let mod = relatedEffects.filter((e) => (e.operation === EffectOperation.Additive)).reduce((total, v) => (total + v.value), 0);
    relatedEffects.filter((e) => (e.operation === EffectOperation.Multiplicative)).forEach((effect) => {
      mod *= effect.value;
    });
    return mod;
  }

  static createFullSet(effects) {
    return [
      new Magic(effects, `Eau et nature`),
      new Magic(effects, `Feu et terre`),
      new Magic(effects, `Vent et foudre`),
      new Magic(effects, `Lumière et vie`),
      new Magic(effects, `Ombre et mort`),
      new Magic(effects, `Esprit`),
      new Magic(effects, `Plans`),
      new Magic(effects, `Temps`),
    ];
  }

}
